<template>
  <ValidationProvider
    :name="$t(sLabel)"
    :rules="required ? 'required' : ''"
    slim
    vid="accountingbookselect"
  >
    <template #default="{ errors, valid }">
      <v-select
        v-model="sValue"
        :error-messages="errors"
        :items="items"
        :success="valid"
        flat
        full-width
        v-bind="obAttrs"
        @change="onSelect"
      />
    </template>
  </ValidationProvider>
</template>

<script lang="ts">
import { Component, Mixins, VModel } from "vue-property-decorator";
import type { SelectItemData } from "@/types/utils";
import SelectFieldMixin from "@/mixins/SelectFieldMixin";

@Component
export default class AccountingBookSelect extends Mixins(SelectFieldMixin) {
  @VModel({ type: String }) sValue!: string;

  get items(): SelectItemData<string>[] {
    return [
      { text: this.$t("sales"), value: "V" },
      { text: this.$t("purchases"), value: "C" },
      { text: this.$t("revenues"), value: "I" },
      { text: this.$t("expenses"), value: "E" },
      { text: this.$t("general.journal"), value: "P" },
    ];
  }

  onSelect(sValue: string) {
    this.$emit("change", sValue);
  }
}
</script>
