import { Component, Prop, Vue } from "vue-property-decorator";
import { assign, defaults, get, isEmpty } from "lodash";

@Component
export default class SelectFieldMixin extends Vue {
  @Prop(String) readonly label!: string;
  @Prop(Boolean) readonly hideLabel!: boolean;
  @Prop(Boolean) readonly required!: boolean;

  sLabelDefault: string = "";

  get sLabel() {
    return this.label ?? this.translatedDefaultLabel();
  }

  get obAttrs() {
    const obAttrProps = assign({}, this.$attrs, this.$props);
    return defaults(obAttrProps, {
      label: this.hideLabel || !this.sLabel ? undefined : this.sLabel,
      menuProps: { offsetY: true },
      hideDetails: this.sHideDetails,
      outlined: true,
      dense: true,
      clearable: true,
    });
  }

  get sHideDetails() {
    return get(this.$attrs, "hideDetails", "auto");
  }

  translatedDefaultLabel(): string | null {
    return isEmpty(this.sLabelDefault)
      ? null
      : (this.$t(this.sLabelDefault) as string);
  }
}
